import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/2019.jpg'
import github from '../img/github-icon.svg'
import twitter from '../img/social/twitter.svg'
import linkedIn from '../img/social/linkedin.svg'

const Footer = class extends React.Component {
  render() {
    return (
<footer className="footer has-background-black has-text-white-ter">
    <div className="content has-text-centered has-background-black has-text-white-ter">
        <div className="container has-background-black has-text-white-ter">
            <div className="columns">
                <div className="column is-3">
                    <section className="menu">
                        <ul className="menu-list">
                            <li>
                                <Link to="/" className="navbar-item">
                                Home
                                </Link>
                            </li>
                            <li>
                                <Link className="navbar-item" to="/about">
                                About
                                </Link>
                            </li>
                        </ul>
                    </section>
                </div>
                <div className="column is-3">
                    <section className="menu">
                        <ul className="menu-list">

                            <li>
                                <Link className="navbar-item" to="/somaliland">
                                Somaliland
                                </Link>
                            </li>
                            <li>
                                <Link className="navbar-item" to="/blog">
                                Tech
                                </Link>
                            </li>                           
                        </ul>
                    </section>
                </div>
                <div className="column is-3">
                    <section className="menu">
                        <ul className="menu-list">
                            <li>
                                <Link className="navbar-item" to="/blog">
                                Latest Stories
                                </Link>
                            </li>
                            <li>
                                <a className="navbar-item" href="/tags" target="_blank" rel="noopener noreferrer">
                                Topics
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
                <div className="column is-3 social">
                    <a title="Github" href="https://github.com/haadka">
                        <img src={github} alt="Github" style={{ width: '1em', height: '1em' }} />
                    </a>
                    <a title="twitter" href="https://twitter.com/haadka">
                        <img className="fas fa-lg" src={twitter} alt="Twitter"
                            style={{ width: '1em', height: '1em' }} />
                    </a>
                    <a title="twitter" href="https://twitter.com/haadka_hawd">
                        <img className="fas fa-lg" src={twitter} alt="Twitter"
                            style={{ width: '1em', height: '1em' }} />
                    </a>
                    <a title="linkedIn" href="https://www.linkedin.com/in/abdidaud/">
                        <img className="fas fa-lg" src={linkedIn} alt="Twitter"
                            style={{ width: '1em', height: '1em' }} />
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
    )
  }
}

export default Footer
